@import "@fuse/scss/core";

// Import app.theme.scss

@import "app/app.theme";

@import "../src/assets/css/custom-design.scss";
@import "../src/assets/css/fixes.scss";
@import "../src/assets/css/responsive.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

// theme variable
:root {
    //   --primary-color: #000f2d;
    // --primary-color: #00ccf2 !important;
    --primary-color: #2D375C !important;
}

.NgxEditor__ResizeHandle{
    left: 0!important;
    top: 0!important;
    bottom: 0!important;
    right: 0!important;
}

//select all user to send notification
.select-all {
    .mat-checkbox-frame {
        border-color: white !important;
    }
}

.no-padding-dialog {
    .mat-dialog-container {
        padding: 0px !important;
    }
}

.mat-form-field-appearance-outline .mat-form-field-prefix {
    top: 0 !important;
}

.LyImageCropper-resizer-ah{
    width: 8px;
    height: 8px;
    background: #2D375C!important;
    border-radius: 50%!important;
    position: absolute;
    touch-action: none;
    bottom: 3px!important;
    right: 3px!important;
    pointer-events: all;
    cursor: nwse-resize;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.legend-title-text {
    display: none !important;
}

.legend-labels {
    text-align: center !important;
}

.tick text {
    font-size: 14px !important;
}

.filtered .mat-form-field-underline {
    height: 0px !important;
    display: none !important;
}

::ng-deep .mat-form-field-underline {
    display: none !important;
}

.filtered .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: transparent !important;
    padding-bottom: 0px !important;
    bottom: 0px !important;
}

.filtered .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0px !important;
}

.filtered .mat-form-field {
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #25d3f4;
    border-radius: 7px;
    line-height: 26px;
}

.filtered .mat-form-field-infix {
    border: 0 !important;
}

.filtered {
    align-items: center;
    justify-content: space-between;
}

.top-spacing {
    padding-top: 12px;
}

.mat-typography p {
    margin: 0;
}

// .changeProfileWrapper mat-dialog-container.mat-dialog-container{
//   padding: 0;
// }

.selection.mat-pseudo-checkbox-checked::after {
    left: 2px !important;
}

.selection .mat-pseudo-checkbox-checked {
    left: 2px !important;
}

.addExsitingContact ::ng-deep .mat-form-field-underline {
    display: none !important;
}

.addGroup .mat-dialog-title {
    margin-bottom: 0 !important;
}

.invitationContent {
    min-height: 200px;
    display: block;

    .ql-container.ql-snow {
        min-height: 200px;
    }
}

.eventRangePicker {
    .mat-form-field{
        .mat-form-field-wrapper{
            .mat-form-field-flex{
                .mat-form-field-infix{
                    display: flex!important;
                }
            }
        }
    }
}

/* 
.template-wrapper{
  .ql-editor{
    p{
      color: red;
      padding: 20px!important;
      background-color: #555!important;
    }
    img{
      width: 150px!important;
      height: 150px!important;
      object-fit: contain!important;
    }
  }
}
*/

// .cdk-overlay-pane{
//   min-width: auto!important;
//   max-width: fit-content!important;
//   .mat-select-panel-wrap{
//     width: 100%!important;
//     .mat-select-panel{
//       width: 100%!important;
//       .contains-mat-select-search{
//         width: 100%!important;
//         .mat-select-search-inner{
//           // width: 100%!important;

//         }
//       }
//     }
//   }
// }

// for phone code picker
.ngx-intl-tel-style {
    padding: 0px !important;

    mat-label {
        width: 100% !important;
        display: block !important;
        margin-bottom: 4px !important;
    }
    
    .selected-dial-code{
        color: #000!important;
    }

    .update_countryCode {
        border-radius: 6px !important;
        width: 100% !important;
        display: block !important;
        height: 48px !important;
        border: 1px solid #c9d5e1 !important;

        .iti.separate-dial-code {
            height: 100% !important;
            width: 100% !important;

            input.custom {
                height: 100% !important;
            }

            .dropdown-menu { 
                .search-container {
                    #country-search-box {
                        padding: 12px 6px !important;
                    }
                }

                .iti__country-list {
                    .iti__country {
                        padding: 12px 6px !important;
                    }
                }
            }
        }
    }
}

.dropdown-menu.country-dropdown{
    .iti__country-list{
        max-height: 250px!important;
    }
}

.ngx-intl-tel-style {
    .iti {
        width: 100%;
        display: flex;
        height: 49px;
        .iti__selected-flag {
            width: 80px !important;
        }
        input {
            border-radius: 6px !important;
            width: 100%;
            padding: 15px 16px 15px 85px !important;
            height: 100%;
            background-color: #edf9fe;
            border: 1px solid #00ccf233;
        }
        .dropdown-menu.country-dropdown{
            width: 400px!important;
        }
    }

    .dropdown-menu {
        // position: static!important;
        z-index: 99;
        background-color: #fff !important;

        input {
            font-size: 14px;
            padding: 14px 14px !important;
            color: #000 !important;
            border-radius: 0 !important;
            border-color: #ccc !important;
            background-color: #fff !important;
        }

        width: 100% !important;

        .iti__country-list {
            .iti__country {
                padding: 10px !important;

                .iti__country-name {
                    font-size: 15px !important;
                }

                .iti__dial-code {
                    font-size: 15px !important;
                }
            }
        }
    }
}

.phoneCodeField {
    mat-error {
        display: block !important;
    }
}

.fileDrop {
    .ngx-file-drop__drop-zone {
        border: 0 !important;
        border-radius: 6px !important;
        height: 100% !important;

        .ngx-file-drop__content {
            height: 100% !important;
        }
    }
}

.backdropBackground {
    background-color: rgba(0, 0, 0, 0.541);
    backdrop-filter: blur(2px) !important;
}

.input-image-icon {
    width: 24px;
    margin-right: 5px;
}

.formTabGroup.mat-tab-group {
    .mat-tab-header {
        .mat-tab-label-container {
            .mat-tab-labels {
                .mat-ripple.mat-tab-label.mat-tab-label-active {
                    .mat-tab-label-content {
                        color: #0a6ebd !important;
                    }
                }

                .mat-ink-bar {
                    background-color: #0a6ebd !important;
                }
            }
        }
    }
}

.formTabGroup.mat-tab-group.mat-primary .mat-ink-bar,
.formTabGroup.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #0a6ebd !important;
}

.timepicker-backdrop-overlay {
    z-index: 1000 !important;
}

.timepicker-overlay {
    z-index: 1001 !important;
}

.add-schedule-wapper {
    height: 600px !important;

    .mat-dialog-content {
        height: calc(100% - 136px);
    }
}

.delete-ticket-dialog {
    .mat-dialog-content {
        min-width: 320px;
    }
}

.table-scrolling-page{
    max-height: calc(100vh - 64px);
    overflow: scroll;
}

.table-scrolling-page::-webkit-scrollbar {
    display: none;
}
  

/* TABLE SCROLLING CSS */ 

.table-wrapper {
    border-radius: 6px;
    border: 1px solid #efefef;
    background-color: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.031372549) !important;
    margin-top: 10px;
    margin-bottom: 30px;
    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        overscroll-behavior: auto;

        .scrolling-wraaper{
            display: flex;
            .mat-table {
                width: 100%;
                max-width: 100%;
                margin-bottom: 1rem;
                display: table;
                border-collapse: collapse;
                margin: 0px;
            }
    
            .mat-row,
            .mat-header-row {
                display: table-row;
            }
    
            .mat-cell,
            .mat-header-cell {
                word-wrap: initial;
                display: table-cell;
                padding: 4px 24px !important;
                line-break: unset;
                width: auto;
                white-space: nowrap;
                overflow: hidden;
                vertical-align: middle;
            }
        }
    }

    .cdk-column-proImage {
        max-width: 70px;
        min-width: 70px;
        width: 70px;

            img {
                height: 40px;
                width: 40px;
                min-width: 40px;
                min-height: 40px;
                margin: 5px;
                object-fit: cover;
                border-radius: 50%;
                padding: 0;
            }
    }
}

// mat redio button
mat-radio-group {
    mat-radio-button {
        label.mat-radio-label {
            .mat-radio-container {
                .mat-radio-outer-circle {
                    border-color: $custom-heading !important;
                }

                .mat-radio-inner-circle {
                    background-color: $custom-heading !important;
                }
            }
        }
    }
}

// style --
mat-select {
    line-height: 26px;

    .mat-select-arrow-wrapper {
        .mat-select-arrow {
            color: $black !important;
        }
    }

    .mat-select-placeholder {
        line-height: 17px;
        font-size: 14px;
        color: $black !important;
        font-weight: 500;
    }

    .mat-select-trigger {
        .mat-select-value {
            .mat-select-value-text {
                span {
                    color: $black !important;
                }
            }
        }
    }

    mat-option.mat-option {
        // .mat-option-pseudo-checkbox{
        // checkbox
        //   .mat-checkbox-ripple .mat-ripple-element,
        //   .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        //     background-color: #2D375C !important;
        //   }
        // }
    }
}

//   for image upload box style

.prev-drp-img {
    position: relative;
    padding: 2px;

    .del-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
        position: absolute;
        bottom: 3px;
        z-index: 2;
        left: 50%;
        padding: 3px;
        transform: translateX(-50%);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 6px;
        }
    }
}

.drop-zone,
.prev-drp-img {
    border: 2px dashed #cadbee;
    border-radius: 10px;
    height: 180px !important;

    .fileDrop {
        p {
            font-family: "Roboto", sans-serif;
            color: #2d375c;
            font-size: 16px;
            font-weight: 600;
            margin: 20px 0 5px 0;
        }

        span {
            font-size: 13px;
            font-family: "Roboto", sans-serif;
            line-height: 15px;
            color: #9c9ea7;
        }
    }
}

.verifierAssignWrapper {
    .mat-dialog-container {
        padding-bottom: 0 !important;
    }
}

body.theme-green-light .custom-spinner.mat-progress-spinner circle, body.theme-green-light .custom-spinner.mat-spinner circle{
    stroke: #ffffff!important;
}


.imageCropSlider{
    .wrapper-q{
        .y-bg-s{
            background-color: #2D375C!important;
        }
        .track-r{
            background-color: #2D375C!important;
        }
        .thumbContainer-t{
            .thumbContent-u{
                &::before{
                    background-color: #2D375C!important;
                    
                }
                .thumb-v{
                    background-color: #2D375C!important;
                    &::before{
                        box-shadow: 0 0 0 8px #2d375c9c;
                        background-color: #2D375C!important;
                    }
                }
            }
            background-color: #2D375C!important;
        }
    }
}

.image_upload_wrpper{
    display: flex;
    justify-content: center;
    align-items: center;
    > div{
        display: flex;
        gap: 8px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &.rounded-wapper{
        border-radius: 50%;
    }
}

.LyImageCropper-ad{
    margin: 0 auto!important;
}

// time zone option in toolbar.
.select_time_zone_option{
    .mat-form-field{
        .mat-form-field-wrapper{
            padding-bottom: 0!important;
            margin: 0!important;
            .mat-form-field-infix{
                padding: 3px 0 10px 0!important;
                border-top: 9px solid transparent;

                .mat-select{
                    .mat-select-trigger{
                        .mat-select-value{ 
                            .mat-select-value-text{
                                span.mat-select-min-line{
                                    font-size: 14px!important;
                                }
                            } 
                        }
                    }
                }
            }
        }
    }
}

// for drop arrow
.select_time_zone_option .mat-form-field.mat-form-field-type-mat-select .mat-form-field-infix .mat-select-trigger .mat-select-arrow-wrapper{
    height: 0!important;
}

.otp-btn{
    span.mat-button-wrapper{
      display: flex;
      padding: 8px;
      align-items: center;
      flex-direction: row;
      gap: 5px;
    }
  }

.canCreateCustomer{
    .mat-checkbox-layout{
        display: flex;
        align-items: start;
        .mat-checkbox-inner-container{
            margin: 6px 8px 0 0!important;
        }
    }
}


.mat-datepicker-popup{
    .mat-datepicker-content{
        .mat-datepicker-content-container{
            .mat-calendar{
                .mat-calendar-content{
                    .mat-calendar-table-header{
                        tr{
                            th{
                                span{
                                    color: #222!important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ceriticate-bg{
    height: auto!important;
    border: 1px dashed #D1D1D1!important;
    background-color: #F9FAFC!important;
    border-radius: 12px!important;
    display: flex;
    align-items: center;
    justify-content: center;
    .openFileSelector{
        width: 100%!important;
        height: 100%!important;
        display: flex;
        align-items: center;
        justify-content: center;
        .yetToUpload{
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: center;
            flex-direction: column;
            p{
                font-size: 18px;
                line-height: 22px;
                color: #000000;
                font-weight: 500;
            }
            span{
                font-size: 16px;
                line-height: 19px;
                color: #000000;
                font-weight: 500;
            }
            img{
                width: 75px;
                margin-top: 15px;
            }
        }
    }
}

.angular-editor-toolbar{
    button{
        font-size: 13px!important;
    }
} 
.angular-editor-textarea{
    min-height: 200px!important;
}

.donwloadCertificateBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    .mat-button-wrapper{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.mat-form-field-underline{
    display: none!important;
}