body.theme-green-light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
body.theme-green-light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary-color) !important;
}

body.theme-green-light .mat-pseudo-checkbox-checked,
body.theme-green-light .mat-pseudo-checkbox-indeterminate,
body.theme-green-light .mat-accent .mat-pseudo-checkbox-checked,
body.theme-green-light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background-color: #2D375C!important;
}

.mat-slide-toggle-label {
  flex-direction: row-reverse !important;
  .mat-slide-toggle-content {
    margin-right: 17px;
  }
  .mat-slide-toggle-bar {
    height: 30px;
    width: 60px;
    border-radius: 15px;
  }

  .mat-slide-toggle-thumb-container {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 3px;
  }
  .mat-slide-toggle-thumb {
    height: 25px;
    width: 25px;
  }
}
body.theme-green-light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $custom-sky-blue !important;
}
body.theme-green-light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #d5edfe !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(29px, 0, 0) !important;
}

body.theme-green-light .mat-accent .mat-slider-track-fill,
body.theme-green-light .mat-accent .mat-slider-thumb,
body.theme-green-light .mat-accent .mat-slider-thumb-label {
  background-color: var(--primary-color);
}

body.theme-green-light .mat-card:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 15px 26px 0px #cccccc69;
}

.mat-menu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

//------------------------------------ mat table----------------------------
.mat-table {
  width: 100%;
  box-shadow: none !important;
  font-family: 'Roboto', sans-serif!important;
  background-color: #ffffff!important;
}

tr.mat-header-row,
.mat-header-row {
  height: 50px!important;
  min-height: 50px!important;
  max-height: 50px!important;
  .mat-header-cell {
    font-size: 16px;
  }
  height: 43px !important;
  min-height: 43px !important;
  background: #fff!important;
  .mat-header-cell {
    color: #000!important;  
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    height: 50px!important;
    // border-bottom: 2px solid #DCDEE2!important;
  }
}
mat-row {
  height: 45px!important;
  max-height: 45px!important;
  min-height: 45px!important;
  &:hover{
    background-color: #F5F5F5!important;
  }
  .tab-link{
    color: #206de5!important;
    transition: all 0.3s ease-in;
    cursor: pointer;
    p{
      color: #206de5!important;
      transition: all 0.3s ease-in;
    }
    &:hover{
      color:  #0860e9;
      p{
        text-decoration: underline;
      }
    }
  }
  .short-col{
    min-width: 250px!important;
    max-width: 250px!important;
    width: 250px!important;
    p{
      display: block!important;
    }
  }
  .short-col-lg{
    min-width: 450px!important;
    max-width: 450px!important;
    width: 450px!important;
    p{
      display: block!important;
    }
  }
}

//apex chart rounded all cournsers
.rounded-corner{
.apexcharts-series path {
  clip-path: inset(5% 5% 5% 0% round 8px);
  }
}

.mat-paginator-container{
  justify-content: center!important;
  .mat-paginator-page-size{
    .mat-paginator-page-size-label{
      font-size: 13px!important;
      color: #000;
      
    }
  }
}