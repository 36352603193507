@media only screen and (max-width: 1540px) {
    .page-layout-wrapper {
        .contant {
            .mat-stepper-horizontal {
                .mat-horizontal-content-container {
                    .mat-horizontal-stepper-content {
                        .new_ui_form {
                            max-width: 80%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1279px) {
    .toolbar_wrapper {
        width: 100% !important;
        left: 0 !important;
    }

    .mat-horizontal-stepper-header-container {
        padding: 14px 12px;

        .mat-step-header {
            padding: 0;
        }
    }
}

@media only screen and (max-width: 900px) {}

@media only screen and (max-width: 768px) {
    .event_view_wrapper .info-row.user-box{
        flex-direction: row!important;
        flex-wrap: wrap!important;
        .guest_item {
            width: calc(100% / 2 - 20px)!important;
        }
    }
    .event_view_wrapper {
        .event_details {
            margin: 12px 0 15px 0 !important;
        }

        .info-title {
            margin-top: 30px !important;
            display: block !important;
        }

        .info-text-box {
            margin-top: 10px !important;
            margin-bottom: 15px !important;
        }

        .info-row {
            flex-direction: column !important;
            margin-bottom: 0 !important;

            .info-item {
                padding-right: 0;
                margin-bottom: 15px !important;
            }
        }
    }

    .activites_wrapper {
        .mat-expansion-panel-content {
            .performer_wrapper {
                .performer_item {
                    width: calc(100% / 2) !important;
                }
            }
        }
    }

    .stepper_wrapper {
        .mat-stepper-vertical {
            width: 100%;
            background-color: transparent !important;

            .mat-step {
                margin-bottom: 15px;

                .mat-step-header {
                    background-color: #fff;
                    padding: 16px 0;
                    border: 1px solid #d7d7d7;

                    .mat-step-icon {
                        display: none;
                    }

                    .mat-step-label {
                        width: 100%;

                        .mat-step-text-label {
                            .stepper_item {
                                padding: 0 !important;
                                flex-direction: row;
                                justify-content: flex-start;
                                place-content: flex-start;
                                align-items: center;

                                .stepper_icon_images {
                                    margin: 0 !important;

                                    img:first-child {
                                        margin: 0 10px 0 15px;
                                    }

                                    img:last-child {
                                        right: 14px;
                                        top: 10px;
                                    }
                                }
                            }
                        }
                    }
                }

                .mat-vertical-content-container {
                    background-color: #fff;
                    padding-left: 15px;
                    padding-top: 0;
                    padding-bottom: 0;
                    margin-left: 0;
                    margin-top: 0;

                    &::before {
                        top: 0px;
                        left: 15px;
                        bottom: 0px;
                        border-style: dashed;
                    }

                    .mat-vertical-content {
                        padding: 10px 20px 10px 20px;
                    }

                    .mat-vertical-stepper-content {
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    .page-layout-wrapper {
        .form.new_ui_form {
            .row {
                flex-direction: column;

                .form-field {
                    .addMoreBtn {
                        right: 40px;
                    }
                }
            }
        }
    }

    .page-layout-wrapper {
        .contant {
            .stepper_wrapper {
                .mat-step {
                    .mat-vertical-content-container {
                        .mat-vertical-stepper-content {
                            .row {
                                flex-direction: row;
                            }
                        }
                    }
                }
            }
        }
    }

    .steper-footer-wrapper {
        justify-content: flex-end;
        display: flex;
    }

    .mat-accordion.eventInfoAccording {
        .mat-expansion-panel-body {
            padding-bottom: 0 !important;
        }
    }

    .event-schedule {
        .redio_tab_wrapper {
            .mat-radio-group {
                margin-bottom: 30px;
                display: flex;
                gap: 15px;
                flex-direction: row;

                .mat-radio-label-content {
                    color: #000000;
                }
            }
        }
    }

    .slot_view_wrapper {
        gap: 16px;
        justify-content: flex-start;
        flex-direction: row;

        .slot_item {
            width: calc(100% / 2 - 10px);
        }
    }

    .custom-filter-wapper {
        margin-bottom: 30px;

        >div {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 692px) {
    .event_details {
        display: flex;
        gap: 15px;
        flex-direction: column;
    }

    

}

@media only screen and (max-width: 639px) {
    .activites_wrapper{
        .mat-accordion{
            .mat-expansion-panel{
                .mat-expansion-panel-content{
                    .mat-expansion-panel-body{
                        .performer_wrapper{
                            gap: 25px;
                            .performer_item{
                                flex-direction: column!important;
                                gap: 8px!important;
                                width: auto!important;
                                img{
                                    margin: 0!important;
                                }
                                .performer_labels{
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .event_view_wrapper {
        .info-row {
            flex-direction: column !important;
            margin-bottom: 0 !important;

            .info-item {
                margin-bottom: 15px !important;
            }
        }

        .info-title {
            margin-top: 30px !important;
            display: block !important;
        }
    }
}

@media only screen and (max-width: 600px) {
    .slot_view_wrapper {
        .slot_item {
            .time_row {
                position: relative;

                .deleteTimeRow {
                    position: absolute;
                    top: 10px;
                    right: 7px;
                    width: 24px;
                    height: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

}

@media only screen and (max-width: 580px) {
    
    .event_view_wrapper .info-row.user-box{
        flex-direction: column!important;
        flex-wrap: wrap!important;
        .guest_item {
            width: calc(100%)!important;
        }
    }
    
    .slot_view_wrapper {
        .slot_item {
            .time_row {
                padding: 8px 10px;
                position: relative;

                >div {
                    p {
                        width: auto !important;
                    }

                    span {
                        margin: 0 8px !important;
                    }
                }

                // .deleteTimeRow{
                //     top: 10px;
                //     right: 7px;
                //     width: 20px;
                //     height: 20px;
                // }
            }
        }
    }
}

@media only screen and (max-width: 540px) {

    .new_ui_form .row .form-field .addMoreBtn {
        right: 13px;
    }

    .new_ui_form {
        .row {
            .form-field {
                &:nth-child(1):nth-last-child(2),
                &:nth-child(2):nth-last-child(1) {
                    flex-basis: 100%;
                    max-width: 99%!important;
                }
            }
        }
    }


    .new_ui_form .row .form-field.input-with-btn input {
        padding-right: 40px;
    }

    .new_ui_form .row .form-field.input-with-btn .mat-icon {
        margin-top: 5px;
    }

    .max-w-2,
    .max-w-3 {
        max-width: 100% !important;
    }

    .custom-filter-wapper {
        margin-bottom: 15px;
    }

    .event-schedule {
        .redio_tab_wrapper {
            .mat-radio-group {
                margin-bottom: 30px;
                display: flex;
                column-gap: 15px;
                flex-direction: column;
            }
        }
    }

    .stepper_wrapper {
        .mat-stepper-vertical {
            .mat-step {
                margin-bottom: 15px;

                .mat-step-header {
                    background-color: #fff;
                    padding: 16px 0;
                    border: 1px solid #d7d7d7;

                    .mat-step-icon {
                        display: none;
                    }

                    .mat-step-label {
                        width: 100%;

                        .mat-step-text-label {
                            .stepper_item {
                                flex-direction: row;
                                justify-content: flex-start;
                                place-content: flex-start;
                                align-items: center;

                                .stepper_icon_images {
                                    margin: 0 !important;

                                    img:first-child {
                                        margin: 0 10px 0 15px;
                                    }

                                    img:last-child {
                                        right: 14px;
                                        top: 10px;
                                    }
                                }
                            }
                        }
                    }
                }

                .mat-vertical-content-container {
                    background-color: #fff;
                    padding: 0;
                    margin-left: 0;
                    margin-top: 0;

                    &::before {
                        display: none;
                    }

                    .mat-vertical-content {
                        padding: 20px 15px;
                    }

                    .mat-vertical-stepper-content {
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    .activites_wrapper {
        margin-top: 20px;

        .mat-expansion-panel-header {
            height: auto !important;
            padding: 8px 14px 8px 10px !important;

            .mat-content {
                flex-direction: column;

                .mat-expansion-panel-header-title {
                    margin-bottom: 4px;
                }
            }
        }

        .mat-expansion-panel-content {
            .mat-expansion-panel-body {
                padding: 10px 14px !important;
            }

            .performer_wrapper {
                margin-top: 14px !important;
                margin-right: 0 !important;

                .performer_item {
                    flex-direction: column;
                    text-align: center;
                    width: calc(100% / 2) !important;
                    align-items: flex-start !important;

                    img {
                        margin: 0 auto 8px auto !important;
                        width: 40px !important;
                        height: 40px !important;
                    }

                    .performer_labels {
                        width: 100%;
                    }
                }
            }
        }
    }

    .page-layout-wrapper {
        .contant {
            .stepper_wrapper {
                .mat-step {
                    .mat-vertical-content-container {
                        .mat-vertical-stepper-content {
                            .slot_view_wrapper {
                                margin-bottom: 20px;
                            }

                            .new_ui_form {
                                max-width: 100%;

                                .row {
                                    flex-direction: column;
                                    gap: 0;
                                    margin-bottom: 0;

                                    .form-field {
                                        width: 100% !important;
                                        margin: 0;

                                        .addMoreBtn {
                                            right: 35px;

                                            img {
                                                width: 23px;
                                                height: 23px;
                                                object-fit: contain;
                                            }
                                        }

                                        &:has(label) {
                                            margin-bottom: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 510px) {
    .event-schedule {
        .redio_tab_wrapper {
            .mat-radio-group {
                margin-bottom: 30px;
                display: flex;
                column-gap: 15px;
                flex-direction: column;
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    
    .event_details {
        gap: 10px;
        flex-direction: column;
        img:first-child {
            width: 100%!important;
            height: auto!important; 
        }
  
        img:last-child {
            width: 100%!important; 
            height: auto!important;
        }
    }

    .page-layout-wrapper {
        padding: 25px 15px;

        .slot_view_wrapper {
            .slot_item {
                width: 100%;
            }
        }
    }

    .activites_wrapper{
        .mat-accordion{
            .mat-expansion-panel{
                .mat-expansion-panel-content{
                    .mat-expansion-panel-body{
                        .performer_wrapper{
                            gap: 20px;
                            flex-wrap: unset;
                            flex-direction: column;
                            // .performer_item{
                            //     flex-direction: column!important;
                            //     gap: 8px!important;
                            //     width: auto!important;
                            //     img{
                            //         margin: 0!important;
                            //     }
                            //     .performer_labels{
                            //         display: flex;
                            //         flex-direction: column;
                            //         align-items: center;
                            //     }
                            // }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 375px) {
    .performer_item {
        padding: 0 5px;
    }
}