@font-face {
  font-family: ProximaNova;
  src: url("./../../assets/fonts/ProximaNova-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: ProximaNovaBold;
  src: url("./../../assets/fonts/ProximaNova-Bold.otf") format("opentype");
  font-weight: 700;
}

// ------------------------ colors -------------------------
// $custom-primary-blue: #001833;
// $custom-secondary-blue: #103259;
// $custom-sky-blue: #00ccf2;
// $custom-pink: #c2529f;

$custom-primary-blue: #F8FBFC;
$custom-secondary-blue: #2D375C;
$icon-button-bg : #ebebeb;
$icon-button-color: #444;

$custom-sky-blue: #00ccf2;
$filter-border-color: #DFE4E9;
$main-theme-color : #2D375C;
$custom-pink: #2D375C;
$custom-heading: #2D375C;
$button-primary-color : #2A8DCE;
$button-disabled-primary-color : #81bce4;
$black: #000000;

.fuse-custom-primary {
  background-color: $custom-primary-blue !important;
  color: white !important;
}


.fuse-custom-primary-light-background {
  background-color: $custom-secondary-blue !important;
  color: white !important;
}


.fuse-custom-primary-light-color {
  color: $custom-secondary-blue !important;
}


.fuse-custom-secondary-background {
  background-color: $custom-sky-blue !important;
  color: white !important;
}


.fuse-custom-secondary-color {
  color: $custom-sky-blue !important;
}


.fuse-custom-pink {
  background-color: $custom-pink !important;

  .nav-link-title {
    color: white !important;
  }

  .normalIcon {
    display: none;
  }

  .forHoverIcon {
    display: inline-block;
  }
}

.nav-collapsable.nav-item:has(.fuse-custom-pink) {
  >a.nav-link {
    background-color: $custom-pink !important;

    .nav-link-title {
      color: white !important;
    }

    .normalIcon {
      display: none;
    }

    .forHoverIcon {
      display: inline-block;
    }

    .collapsable-arrow {
      color: #ffffff;
    }
  }

  .fuse-custom-pink {
    background-color: #F8FBFC !important;

    .nav-link-title {
      color: $custom-pink !important;
      font-weight: 600;
    }
  }
}

.nav-link.active {
  background-color: $custom-pink !important;

  .nav-link-title {
    color: white !important;
  }

  .normalIcon {
    display: none !important;
  }

  .forHoverIcon {
    display: inline-block !important;
  }
}

.forHoverIcon {
  display: none;
}

.mat-calendar-body-selected {
  background-color: $custom-secondary-blue !important;
}

// ------------------------- elements ----------------------------//
.cust-mat-select {
  margin-right: 16px;

  .mat-select-value {
    color: white !important;
  }
}

.drp-search {
  position: relative;

  input {
    padding: 14px 26px 14px 16px;
    width: 100%;
    border-bottom: 2px solid #ccc;
    color: #000;
    font-weight: 500;
  }

  mat-icon {
    color: #ccc;
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 12px;
  }
}

.cust-chip {
  background-color: $custom-secondary-blue !important;
  border-radius: 5px !important;
  color: white !important;

  mat-icon {
    color: white !important;
    opacity: 1 !important;
  }
}

.not-found {
  padding-left: 1rem;
}

.search-box,
.search-box {
  &:focus-within {
    .search-icon {
      cursor: pointer;
      position: absolute;
      left: calc(100% - 32px);
      padding: 0px 4px;
      top: 0px;
      background: $main-theme-color;
      border-radius: 0 5px 5px 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      mat-icon {
        line-height: 26px;
        color: #fff;
      }
    }

    input {
      padding-left: 0px;
      padding-right: 24px;
    }
  }

  border: 1px solid $filter-border-color;
  display: inline-block;
  font-family: 'Roboto',
  sans-serif !important;
  border-radius: 5px;
  background: white;
  padding: 9px;
  position: relative;

  input {
    padding-left: 24px;
    padding-right: 0px;
    font-family: 'Roboto', sans-serif !important;

    &:focus {}

    transition: ease 0.5s;
  }

  .search-icon {
    position: absolute;
    left: 6px;
    line-height: 0px;
    transition: ease 0.5s;

    mat-icon {
      color: $main-theme-color;
    }
  }

  .search-clear {
    display: none;
  }
}

.event-card {
  border-radius: 6px !important;
  border: 1px solid #EFEFEF !important;
  background-color: #ffffff !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.031372549) !important;
}

.search-box-with-data {
  .search-icon {
    cursor: pointer;
    position: absolute;
    left: calc(100% - 32px);
    top: 0px;
    padding: 0px 4px;
    line-height: 0px;
    background: $main-theme-color;
    border-radius: 0 5px 5px 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    mat-icon {
      line-height: 26px;
      color: #ffffff;
    }
  }

  .search-clear {
    display: flex;
    position: absolute;
    right: 32px;
    top: 0;
    text-align: left;
    align-items: center;
    justify-content: center;
    height: 100%;

    mat-icon {
      font-size: 19px;
      line-height: 26px;
      cursor: pointer;
      color: #222;
    }
  }

  input {
    padding-left: 0px;
    padding-right: 24px;
    color: #222;
  }
}

mat-card.mat-card {
  padding: 30px 25px !important;
}

.form {
  padding-right: 0px;
  padding-left: 0px;

  .row {
    margin-bottom: 20px;
    display: flex;
    gap: 20px;

    .max-w-3 {
      max-width: calc(100% / 3);
    }

    .max-w-2 {
      max-width: calc(100% / 2);
    }

    .form-field {
      width: 33.33%;
      // padding: 15px 22px;

      >label {
        // font-size: 14px;
        // color: $custom-secondary-blue !important;
        // display: block;
        // margin-bottom: 5px;
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        color: #2D375C !important;
        display: block;
        margin-bottom: 5px;
      }

      input[type="text"],
      input[type="number"],
      input[type="password"],
      input,
      mat-select,
      ngx-intl-tel-input .iti--allow-dropdown input,
      textarea {
        background-color: #ffffff;
        border: 1px solid #DFE4E9;
        line-height: 17px !important;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 500;
        caret-color: #3c4252;
        padding: 15px 16px;
        color: #000;
        width: 100%;
        border-radius: 6px;
        min-height: 48px;
      }

      

      mat-radio-button .mat-radio-label-content {
        color: #000;
      }



      input[type="file"] {
        min-height: auto;
      }

      .err {
        color: red;
        font-size: 12px;
      }

      // mat check box
      .mat-checkbox {
        .mat-checkbox-layout {
          .mat-checkbox-label {
            color: #000000 !important;
          }
        }
      }
    }
  }

  .field-with-add-btn {
    width: calc(33.33% - 24px);
  }
}

.mat-accordion {
  .mat-expansion-indicator {
    &::after {
      border-color: #000000;
    }
  }
}

.mat-menu-panel {
  overflow: visible !important;
  min-height: 20px !important;
}

.toggle-btn-wrapper {
  width: 33.33%;
  display: flex;
  align-items: center;
  padding: 15px 22px;
}

.drop-zone {
  border: 2px dotted $custom-sky-blue;
  border-radius: 6px;
  height: 240px;
  width: 100% !important;
  position: relative;

  p {
    color: #535353;
    font-size: 16px;
  }
}

.empty-table-msg {
  text-align: center;
  padding: 20px;
  color: #000;
}

.small-drop-zone {
  border: 2px dotted $custom-sky-blue;
  border-radius: 6px;
  height: 80px;
  width: 100% !important;
  position: relative;

  p {
    color: #828282;
    font-size: 16px;
  }
}

.ticket-background-drop-zone {
  border: 2px dotted $custom-sky-blue;
  border-radius: 6px;
  height: 150px;
  width: 100% !important;
  position: relative;

  p {
    color: #828282;
    font-size: 14px;
  }
}

.fileDrop {
  color: var(--primary-color);
  left: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  >div{
    padding: 26px;
  }
  mat-icon {
    font-size: 29px;
  }
}

.prev-drp-img {
  height: 210px;
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 7px;
}

// .prev-banner{
//   border: 2px dotted $custom-sky-blue;
//   height: 180px!important;
//   width: 290px!important;
//   border-radius: 7px;
//   overflow: hidden!important;

// }
// .prev-drp-img:nth-of-type(2){
//   height: 180px;  
//   width: 290px;
//   border-radius: 7px;
//   overflow: hidden;
//   align-items: center;
//   display: flex;
//   justify-content: center;
// }


//------------------------- general -------------------------

.cursor-pointer {
  cursor: pointer;
}

.back-icon {
  color: $custom-secondary-blue;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 20px;
  display: flex;

  mat-icon {
    font-size: 30px;
    height: 30px;
  }
}

.nav-item {
  font-size: 15px;

  .subTitle {
    font-size: 12px;
  }
}

.del-btn {
  background-color: $icon-button-bg !important;
  color: $icon-button-color !important;
  margin-left: 5px !important;
  min-width: 40px !important;
  padding: 0px !important;
}

.cust-btn {
  background-color: $button-primary-color !important;
  color: white !important;
  margin-left: 5px !important;

  &[disabled] {
    background-color: $button-disabled-primary-color !important;
  }
}

.cust-blue-btn {
  background-color: $custom-secondary-blue !important;
  color: white !important;
  margin-left: 5px !important;
}

.page-title {
  color: $custom-secondary-blue;
  font-family: 'Roboto', sans-serif;
  line-height: 29px;
  font-weight: 700;
  font-size: 24px;
}

.chips-wrapper {
  background: #f0f9ff;
  border: 1px solid #00ccf233;
  padding: 14px;
  margin-bottom: 7px;
  border-radius: 6px;
  margin-top: 15px;
  max-height: 150px;
  overflow-y: scroll;
}

//------------------------- filter -------------------------
.filter-wrapper {
  text-align: right;
}

@media (min-width: 564px) {
  .md-drppicker.ltr {
    // left: 523px !important;
    width: max-content !important;
    left: auto !important;
    right: 0px !important;
  }
}

.md-drppicker td.active,
.md-drppicker td.active:hover {
  background-color: $custom-sky-blue !important;
}

.md-drppicker .btn {
  background-color: $custom-sky-blue !important;
}


.date-range,
.status-drp {
  width: 193px;
  display: inline-block;
  background: white;
  padding: 7px 9px;
  max-height: 40px;
  border: 1px solid $filter-border-color;
  border-radius: 5px;
  text-align: left;

  input {
    font-family: 'Roboto', sans-serif !important;

    &::placeholder {
      font-family: 'Roboto', sans-serif !important;
      color: #222222;
    }
  }

  .mat-select {
    .mat-select-placeholder {
      color: #222222;
    }

    .mat-select-trigger {
      .mat-select-arrow-wrapper {
        .mat-select-arrow {
          color: #222222 !important;
        }
      }
    }
  }
}

.date-range-picker {
  font-family: 'Roboto', sans-serif !important;

  width: 230px;

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-19px) scale(0.75) !important;
  }

  .mat-form-field {
    width: 100%;

    .mat-form-field-wrapper {
      padding: 0 !important;

      .mat-form-field-flex {
        background-color: #ffffff;

        .mat-form-field-infix {
          padding: 7px 0 !important;
          top: -6px !important;
          border: 0 !important;

          .mat-date-range-input {
            line-height: 20px !important;
            font-family: 'Roboto', sans-serif !important;
            color: #222222;

            .mat-date-range-input-container {
              position: relative !important;
              top: 5px !important;
              color: #222222;
            }
          }
        }

        .mat-form-field-label {
          font-size: 15px !important;
          line-height: 20px !important;
          color: #222222;
          font-family: 'Roboto', sans-serif !important;
        }

        .mat-form-field-suffix {
          top: 5px !important;
          right: -10px !important;
        }

        .mat-form-field-outline {
          border-radius: 5px !important;
        }
      }
    }

    // .mat-form-field-wrapper{
    //   margin:0!important;
    //   padding:0!important;
    //   .mat-form-field-infix{
    //     display: flex;
    //   }
    // }
  }
}

.date-range input[type=text] {
  width: 100%;
}

.mat-select-arrow {
  color: $custom-sky-blue !important;
}

.clearFilter {
  margin-right: 5px;
  padding: 0px;
  cursor: pointer;
  background-color: #ffffff;
  display: inline-block;
  // border: 1px solid $main-theme-color;
  border-radius: 50%;
}

.table-icon {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 5px 0;
}

.borderSingle {
  border: 1px solid #ccc;
  border-radius: 50%;
}

.tiles-card-item {
  min-height: 122px;
  align-items: center !important;
}

.custom-filter-wapper {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: end;
  align-items: center;
  gap: 10px;

  .clearFilter {
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 7px;
  }

  .search-box {
    >input {
      width: 100%;
    }
  }
}

//---------------------media css--------------------------
@media only screen and (min-width: 1280px) {
  .tiles-item {
    margin-right: 12px !important;
    align-items: flex-start !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


// }
// @media only screen and (min-width: 1290px){
//   .tiles-card-item{
//     min-height: 100px!important;
//   }
// }
// @media only screen and (min-width: 960px){
//   .tiles-card-item{
//     min-height: 122px!important;
//     align-items: flex-start!important;
//   }
// }

.sendInvitation .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px !important;
}

.sendInvitation .mat-form-field-infix {
  border-top: 0 !important;
  padding-top: 0 !important;
}

.text-primary-blue {
  color: $button-primary-color !important;
}

// new ui design css
.page-layout-wrapper {
  padding: 10px 26px 30px 26px;
  width: 100%;

  h2 {
    color: $main-theme-color;
    font-size: 17px;
    line-height: 26px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
  }

  .active-btn {
    // background-color: $button-primary-color!important;
    height: 42px;
    padding: 0 24px 0 24px;
    border-radius: 8px;

    .mat-button-wrapper {
      // color: #ffffff;
      display: flex;
      font-size: 15px;
      font-family: 'Roboto', sans-serif;
      line-height: 40px;
      font-weight: 500;
      align-items: center;

      .mat-icon {
        font-size: 20px;
        display: block;
        line-height: 23px;
        width: 20px;
        margin-left: 3px;
      }
    }

    &.btn-right-icon{
      padding: 0 25px 0 17px!important;
    }
    &.btn-left-icon{
      padding : 0 17px 0 25px!important;
    }
  }

  .steper-footer-wrapper {
    margin-top: 15px;
    gap: 15px;
  }

  .active-btn.flat-theme-btn {
    background-color: $button-primary-color !important;

    .mat-button-wrapper {
      color: #fff !important;
    }
  }

  .active-btn.stroked-theme-btn {
    background-color: #fff;
    border-color: $button-primary-color !important;

    .mat-button-wrapper {
      color: $button-primary-color;

      .mat-icon {
        margin-right: 3px;
        margin-left: 0 !important;
      }
    }
  }


  .active-btn.active-btn-icon.btn-left-icon.mat-flat-button.mat-button-base.mat-button-disabled {
    background-color: $button-disabled-primary-color !important;
  }

  .contant {

    // for create event screen
    .stepper_wrapper {
      display: flex;
      justify-content: space-around;

      .mat-stepper-horizontal {
        width: 100%;
        background-color: transparent !important;

        .mat-step-icon {
          display: none;
        }

        // for steper
        .mat-horizontal-stepper-header-container {
          background-color: #ffffff;
          padding: 17px 15px;
          border-radius: 6px;
          border: 1px solid #EFEFEF;
          box-shadow: 0px 2px 5px #00000008 !important;

          .mat-horizontal-stepper-header {
            padding: 7px !important;
          }

          .mat-step-optional{
            text-align: center;
          }

          .mat-stepper-horizontal-line {
            border-top-width: 1px;
            border-top-style: dashed;
            flex: auto;
            height: 0px;
            margin-bottom: -46px;
            min-width: 0 !important;
          }
        }

        // this is for contant
        .mat-horizontal-content-container {
          margin-top: 28px;
          padding: 30px 25px;
          border-radius: 6px;
          border: 1px solid #EFEFEF;
          background-color: #ffffff;
          box-shadow: 0px 2px 5px #00000008 !important;

          .tab-title {
            margin-bottom: 20px;

            h5 {
              font-family: 'Roboto', sans-serif;
              font-size: 16px;
              line-height: 19px;
              color: $custom-heading;
              font-weight: 600;
            }
          }

          .mat-horizontal-stepper-content {
            color: $black;
          }

          .steper-footer-wrapper {
            display: flex;
            justify-content: flex-end;

          }
        }
      }
      .mat-stepper-vertical{
        .mat-step{
          .mat-step-header{
            .mat-step-label{
              display: flex;
              gap: 15px;
              align-items: center;
            }
          }
        }
      }
      
      .stepper_item {
        position: relative;
        padding: 0 24px !important;
        display: flex;
        flex-direction: column;
        text-align: center;
        place-content: center;

        .stepper_icon_images {
          margin-bottom: 10px;

          .checked {
            display: none;
          }

          img {
            margin: 0 auto;
          }
        }

        h4 {
          font-size: 16px;
          color: #2D375C;
          margin: 0;
        }
      }

      .inProgress {
        h4 {
          font-weight: 600;
          margin: 0;
        }
      }

      .isDone {
        .stepper_icon_images {
          .checked {
            display: block;
            right: 18px;
            top: 22px;
            position: absolute;
          }
        }
      }

      .event-schedule {
        .redio_tab_wrapper {
          .mat-radio-group {
            margin-bottom: 30px;
            display: flex;
            gap: 18px;
          }
        }
      }

      .venue_activity {}

      .event_Details {
        .tab-title {
          margin-bottom: 11px !important;
        }

        .prev-drp-img {
          position: relative;
          padding: 2px;

          .del-icon {
            width: 24px;
            height: 24px;
            cursor: pointer;
            position: absolute;
            bottom: 3px;
            z-index: 2;
            left: 50%;
            padding: 3px;
            transform: translateX(-50%);

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 6px;
            }
          }
        }

        .drop-zone,
        .prev-drp-img {
          border: 2px dashed #CADBEE;
          border-radius: 10px;
          height: 180px !important;

          .fileDrop {
            p {
              font-family: 'Roboto', sans-serif;
              color: #2D375C;
              font-size: 16px;
              font-weight: 600;
              margin: 20px 0 5px 0;
            }

            span {
              font-size: 13px;
              font-family: 'Roboto', sans-serif;
              line-height: 15px;
              color: #9C9EA7;
            }
          }
        }

        quill-editor {
          height: calc(100% - 70px) !important;

          .ql-toolbar {
            background-color: #f8f8f5;
          }

          .ql-container.ql-snow {
            border: 1px solid #ccc;
            border-top: 0;
          }
        }
      }

      .ready_to_Publish {
        .create_ticket_wrapper {
          display: flex;
          margin-bottom: 37px;
          flex-wrap: wrap;

          .ticket_item {
            width: 130px;
            padding: 12px 18px;
            margin-bottom: 20px;
            border-radius: 6px;
            margin-right: 20px;
            border: 1px solid #DFE4E9;
            position: relative;

            h5 {
              margin: 0 0 5px 0;
              font-size: 20px;
              font-family: 600;
              color: #2D375C;
              overflow: hidden;
              cursor: pointer;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              line-height: 24px;
            }

            p {
              font-size: 12px;
              line-height: 14px;
              color: #000000;
            }

            &::after {
              display: block;
              content: "";
              position: absolute;
              width: 22px;
              height: 22px;
              top: 24px;
              clip-path: circle(50% at 50% 50%);
              left: -10px;
              border-top: 1px solid #DFE4E9;
              transform: rotate(45deg);
              border-radius: 50%;
              background-color: #ffffff;
              border-right: 1px solid #DFE4E9;
            }

            &::before {
              display: block;
              content: "";
              position: absolute;
              width: 22px;
              height: 22px;
              top: 24px;
              clip-path: circle(50% at 50% 50%);
              right: -10px;
              border-top: 1px solid #DFE4E9;
              transform: rotate(-45deg);
              border-radius: 50%;
              background-color: #ffffff;
              border-left: 1px solid #DFE4E9;
            }
          }
        }
      }
    }
  }
}



.activites_wrapper {
  .mat-expansion-panel {
    box-shadow: none !important;
    margin-bottom: 15px !important;

    .mat-expansion-panel-header {
      height: 60px !important;
      border-radius: 6px !important;
      background-color: #F9F9F9 !important;
      border: 1px solid #DFE4E9 !important;

      .mat-expansion-panel-header-description {
        color: #000000 !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        font-family: 'Roboto', sans-serif !important;
      }

      .mat-expansion-indicator {
        height: 22px !important;

        &::after {
          color: #000000 !important;
        }
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 20px 18px !important;

        p {
          font-size: 14px !important;
          color: #000000 !important;
          line-height: 24px !important;
        }

        .performer_wrapper {
          width: 100% !important;
          display: flex !important;
          margin-top: 25px !important;
          margin-right: 25px !important;
          flex-wrap: wrap !important;

          .performer_item {
            width: calc(100% / 3) !important;
            margin-bottom: 15px !important;
            display: flex !important;
            align-items: center !important;

            img {
              margin-right: 9px !important;
              width: 50px !important;
              border-radius: 50% !important;
              height: 50px !important;
              object-fit: cover !important;
            }

            h5 {
              font-size: 16px !important;
              color: #000000 !important;
              line-height: 19px !important;
              margin: 0 !important;
            }

            p {
              font-size: 14px !important;
              line-height: 17px !important;
              color: #000000 !important;
            }
          }
        }
      }
    }
  }
}


// form new Ui 
.new_ui_form {
  max-width: 1100px;

  .row {
    width: 100%;
    margin-bottom: 20px;
    gap: 20px;
    display: flex;

    .multiselect-wrapper {
      &:hover {
        .multiselected-list {
          display: block;
        }
      }
    }

    >.form-field {
      width: 100% !important;
    }

    .form-field {
      padding: 0;

      &:nth-child(1):nth-last-child(2),
      &:nth-child(2):nth-last-child(1) {
        // width: 50% !important;
        flex-basis: 50%;
        max-width: 49%;
      }

      .date-time-picker {
        position: relative;

        .mat-datepicker-toggle {
          position: absolute;
          right: 0px;
          height: 100%;

          .mat-button-base {
            width: 47px;
            height: 100%;

            .mat-button-wrapper {
              .mat-datepicker-toggle-default-icon {
                margin: 0 auto;
              }
            }
          }
        }
      }

      label {
        font-size: 14px;
        line-height: 17px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;

        img {
          margin-left: 6px;
          width: 14px;
          height: 14px;
          object-fit: contain;
        }
      }

      // input & select
      input,
      mat-select {
        height: 48px;
        border-radius: 6px;
        border: 1px solid #DFE4E9;
        background-color: #ffffff;
        line-height: 17px;
        font-size: 14px;
        font-weight: 500;
      }

      mat-select.mat-select-disabled {
        border-color: #f3f3f3;
        background-color: #f3f3f3;
      }

      // checkbox
      .mat-checkbox-ripple .mat-ripple-element,
      .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: #2D375C !important;
      }

      mat-select {
        line-height: 26px;

        .mat-select-arrow-wrapper {
          .mat-select-arrow {
            color: $black !important;
          }
        }

        .mat-select-placeholder {
          line-height: 17px;
          font-size: 14px;
          color: $black !important;
          font-weight: 500;
        }

        .mat-select-trigger {
          .mat-select-value {
            .mat-select-value-text {
              span {
                color: $black !important;
              }
            }
          }
        }

        mat-option.mat-option {
          // .mat-option-pseudo-checkbox{
          // checkbox
          //   .mat-checkbox-ripple .mat-ripple-element,
          //   .mat-checkbox-checked.mat-accent .mat-checkbox-background {
          //     background-color: #2D375C !important;
          //   }
          // }

        }
      }

      input {
        padding: 15px 16px;
        color: $black;

        &::placeholder {
          line-height: 17px;
          font-size: 14px;
          color: #9A9A9A;
          font-weight: 500;
        }
      }

      .addMoreBtn {
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);

        img {
          width: 26px;
          height: 26px;
          object-fit: contain;
        }
      }

      .err {
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
      }


      .multiselected-list {
        z-index: 1;
        display: none;
        position: absolute;
        width: 100%;
        padding-top: 8px;
        top: 40px;

        >div {
          background-color: #ffffff;
          border: 1px solid #ddd;
          padding: 10px;
          border-radius: 6px;
          height: 120px;
          width: 100%;
          // max-width: 350px;
          overflow-y: scroll;
          box-shadow: 0px 0px 5px 2px #f7f7f7;

          .cust-chip {
            background-color: #7788CB !important;
          }
        }

        &:hover {
          display: block;
        }
      }

      .date-range-picker {
        font-family: 'Roboto', sans-serif !important;

        width: 100%;

        .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
          transform: translateY(-19px) scale(0.75) !important;
        }

        .mat-form-field {
          width: 100%;

          .mat-form-field-wrapper {
            padding: 0 !important;

            .mat-form-field-flex {
              background-color: #ffffff;

              .mat-form-field-infix {
                padding: 0 !important;
                border: 0 !important;


                .mat-date-range-input {
                  line-height: 30px !important;
                  font-family: 'Roboto', sans-serif !important;
                  color: #222222;

                  .mat-date-range-input-container {
                    position: relative !important;
                    top: 0 !important;
                    display: flex !important;
                    align-items: center !important;
                    color: #222222;

                    .mat-date-range-input-separator {
                      margin-top: 7px;
                    }

                    .mat-date-range-input-start-wrapper {
                      height: 38px !important;
                      padding: 0 5px !important;

                      input {
                        height: 38px !important;
                        margin-top: 4px !important;
                        min-height: 38px !important;
                        padding: 0 !important;
                        border: 0 !important;
                      }
                    }

                    .mat-date-range-input-end-wrapper {
                      height: 38px !important;
                      padding: 0 5px !important;

                      input {
                        height: 38px !important;
                        margin-top: 4px !important;
                        min-height: 38px !important;
                        padding: 0 !important;
                        border: 0 !important;
                      }
                    }
                  }
                }
              }

              .mat-form-field-label {
                font-size: 15px !important;
                line-height: 20px !important;
                color: #222222;
                font-family: 'Roboto', sans-serif !important;
              }

              .mat-form-field-suffix {
                top: 8px !important;
                right: -10px !important;
              }

              .mat-form-field-outline {
                border-radius: 5px !important;
              }

            }
          }

          // .mat-form-field-wrapper{
          //   margin:0!important;
          //   padding:0!important;
          //   .mat-form-field-infix{
          //     display: flex;
          //   }
          // }
        }
      }


      // mat redio button
      mat-radio-group {
        mat-radio-button {
          margin-right: 50px;
        }
      }
    }
  }

  .mat-accordion.eventInfoAccording {
    .mat-expansion-panel {
      box-shadow: none;

      .mat-expansion-panel-header {
        padding: 0;

        .mat-content {
          flex: inherit;
        }

        &:hover {
          background-color: #ffffff !important;
        }

        .mat-expansion-indicator {
          height: 25px;

          &::after {
            color: $black;
          }
        }

        .mat-expansion-panel-header-title {
          font-size: 15px;
          line-height: 18px;
          font-weight: 500;
          color: $black;
        }
      }

      .mat-expansion-toggle-indicator-after {
        &:hover {
          background-color: #ffffff !important;
        }
      }

      .mat-expanded {
        height: 48px !important;
      }

      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          padding: 10px 0 30px 0;
        }
      }
    }
  }
}

//link button
.link_btn {
  display: flex;
  align-items: center;

  span {
    transition: 0.2s all ease-in-out;
    color: #2A8DCE;
  }

  mat-icon {
    font-size: 17px;
    line-height: 22px;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
}

// in create order schedule event screen redio tab button
.redio_tab_wrapper {
  .mat-radio-group {
    .mat-tab-group {
      .mat-tab-header {
        border: none !important;

        .mat-tab-label-container {
          .mat-tab-list {
            .mat-tab-labels {
              .mat-tab-label {
                min-width: 134px !important;
                padding: 0px 0 0 10px;
                margin-right: 50px;
                opacity: 1 !important;

                .mat-radio-label-content {
                  font-family: 'Roboto', sans-serif !important;
                  font-size: 14px !important;
                  font-weight: 600 !important;
                  line-height: 21px !important;
                  padding-left: 12px;
                }
              }
            }

            .mat-ink-bar {
              display: none !important;
            }
          }
        }
      }

      .mat-tab-body-wrapper {
        padding-top: 15px;
      }
    }
  }
}

// in create order slot style
.slot_view_wrapper {
  display: flex;
  padding-bottom: 30px;
  padding-left: 4px;
  padding-right: 4px;
  gap: 20px;
  flex-wrap: wrap;

  .slot_item {
    border: 1px solid #DFE4E9;
    border-radius: 6px;
    width: 290px;
    overflow: hidden;
    -webkit-box-shadow: 0px 3px 2px 0px rgba(223, 228, 233, 0.75);
    -moz-box-shadow: 0px 3px 2px 0px rgba(223, 228, 233, 0.75);
    box-shadow: 0px 3px 2px 0px rgba(223, 228, 233, 0.75);

    .slot_header {
      padding: 12px 12px;
      background-color: #7788CB;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: #ffffff;
        font-weight: 600;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        line-height: 18px;
      }

      &:has(button) {
        padding: 0 12px !important;
      }
    }

    .time_item {

      .time_row {
        padding: 12px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        >div {
          display: flex;
          align-items: center;
          min-height: 20px;
          max-height: 20px;
          height: 20px;

          input,
          p {
            min-height: 20px;
            max-height: 20px;
            height: 20px;
            width: 80px;
            font-size: 14px;
            font-family: 'Roboto', sans-serif;
            line-height: 18px;
            font-weight: 600;
            padding: 0 !important;
            color: #000000;

            &::placeholder {
              font-weight: 600;
              line-height: 18px;
              font-family: 'Roboto', sans-serif;
              color: #2A8DCE;
            }
          }

          span {
            min-height: 20px;
            line-height: 20px;
            display: block;
            margin-right: 22px;
            max-height: 20px;
            height: 20px;
          }
        }

        span {
          font-size: 14px;
          font-weight: 600;
          color: $black;
        }

        .deleteTimeRow {
          display: flex;
          align-items: center;
        }
      }
    }

    .slot_without_time {
      padding: 40px 0;
      text-align: center;

      span {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
      }
    }
  }
}

// in create Multiple Days Event
.multiple_days_event {

  // top button
  .set_date_schedule_btn_wrapper {
    margin-bottom: 20px;

    .set_date_schedule_btn {
      display: inline-block;
      cursor: pointer;

      img {
        margin: 0;
        vertical-align: middle;
        display: inline-block;
        width: 17px;
        height: 17px;
        object-fit: contain;
      }

      span {
        vertical-align: middle;
        font-size: 16px;
        padding-left: 7px;
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        line-height: 19px;
      }

      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }
  }
}

// in create event ticket_sale
.ticket_sale {
  mat-radio-group.mat-radio-group {
    mat-radio-button.mat-radio-button {
      margin-bottom: 15px;
      margin-right: 20px;

      .mat-radio-label {
        .mat-radio-label-content {
          color: #000000 !important;
        }
      }
    }
  }
}

.info_labels {
  margin: 10px 0 30px 0;
  display: inline-flex;
  padding: 12px;
  background-color: #EEF1FF;
  border-radius: 6px;
  align-items: flex-start;

  img {
    display: inline-block;
  }

  p {
    padding-left: 5px;
    display: inline-block;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    font-weight: 500;

    span {
      display: inline-block;
      padding: 0 5px;
      font-weight: 600;
    }
  }
}

// Common Dialog Wrapper 
.commonDialogWrapper {
  .mat-dialog-container {
    border-radius: 20px;
  }
}

.dialogBackdrop {
  background-color: rgba(0, 0, 0, 0.541);
  backdrop-filter: blur(2px) !important;
}


/* 
  custom style mat-card
*/

.custom-card {
  background-color: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.031372549) !important;
  border: 1px solid #EFEFEF;
  margin-top: 28px;
  padding: 30px 25px;
  border-radius: 6px;
}

// view event
.event_view_wrapper {

  .info-row {
    width: 100%;
    max-width: 100%;
    .info-item-custom {}

    
    .info-text-box {
      color: #000;
      margin-top: 15px;
      margin-bottom: 25px;
      border: 1px solid #D2D2D2;
      box-shadow: 0 0 1px 2px #c7c7c70d;
      padding: 15px;
      border-radius: 6px;
      max-height: 300px;
      // min-height: 300px;
      overflow-x: scroll;
      // h1,h2,h3,h4,h5,h6{
      //   color: #000;
      // }
    }

    .info-item {
      padding-right: 20px;
      width: 100%;

      span.info-label {
        font-size: 14px;
        line-height: 18px;
        color: #88888A;
        margin-bottom: 6px;
        display: block;
      }

      h2 {
        font-size: 26px;
        line-height: 32px;
        color: #000;
        margin-bottom: 0;
      }

      p {
        font-size: 15px;
        line-height: 18px;
        color: #000;
        margin-bottom: 0;
        font-weight: 600;
      }

    }

    .info-item.category_wrapper {
      .category_item_wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 5px;

        >div {
          padding-top: 5px;
          padding-bottom: 5px;
          padding-right: 8px;
          padding-left: 8px;
          background-color: #7788CB;
          border-radius: 6px;

          img {
            width: 25px;
            height: 25px;
            object-fit: contain;
          }

          p {
            color: #fff;
            font-size: 15px !important;
            font-weight: 400 !important;
          }
        }
      }
    }

    .event_details {
      display: flex;
      gap: 15px;

      img:first-child {
          width: 220px;
          height: 220px;
          object-fit: cover;
          padding: 5px;
          border: 1px solid #ddd;
          border-radius: 6px;
      }

      img:last-child {
          height: 220px;
          width: 350px;
          object-fit: cover;
          padding: 5px;
          border: 1px solid #ddd;
          border-radius: 6px;
      }
    }
  }

  .info-row.dateFlotWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;

    p {
      font-size: 15px;
      color: #000;
      line-height: 20px;
      font-weight: 600;
    }

    .slotWrapper {
      margin: 5px 0 10px 0;
      display: flex;
      align-items: center;
      gap: 8px;

      .slotChip {
        font-size: 14px;
        line-height: 18px;
        border-radius: 15px;
        color: #fff;
        padding: 8px 16px 6px 16px;
        background-color: #98A6DE;
      }
    }

    >div {
      position: relative;

      &:first-child {
        &::after {
          display: none;
        }
      }

      &::after {
        position: absolute;
        content: "";
        display: block;
        left: -10px;
        width: 2px;
        height: 100%;
        background-color: #f7f7f7;
        border-radius: 1px;
        z-index: 1;
        top: 0;
      }
    }
  }

  .info-row.faq-List-Wrapper {
    margin-bottom: 30px;

    .info-title {
      margin-bottom: 20px;
    }

    .mat-accordion {
      .mat-expansion-indicator {
        &::after {
          border-color: #424242 !important;
        }
      }

      .mat-expansion-panel {
        border: 1px solid #e9e9e9;
        margin-top: 0 !important;
        margin-bottom: 10px !important;
        box-shadow: none !important;

        .mat-content {
          .mat-expansion-panel-header-title {
            color: #000 !important;
          }
        }

        .mat-expansion-panel-header.mat-expanded {
          height: auto!important;
          padding: 15px!important;
        }

        .mat-expansion-panel-body {
          padding-top: 15px !important;
          color: #444444 !important;
          padding-left: 16px!important;
          padding-right: 16px!important;
        }
      }

      .mat-expansion-panel-header{
        height: auto!important;
        padding: 15px!important;
      }
      .mat-expansion-panel-spacing {
        .mat-expansion-panel-header {
          border-bottom: 1px solid #e9e9e9;
        }
      }
    }
  }

  .user-box {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .guest_item {
      width: calc(100% / 3 - 20px);
      display: flex;
      align-items: center;
      padding: 15px;
      border: 1px solid #D2D2D2;
      gap: 15px;
      box-shadow: 0px 1px 2px #0000000D;
      border-radius: 8px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
      }

      >div {
        display: flex;
        flex-direction: column;
        gap: 2px;

        p {
          color: #000;
          font-size: 16px;
          font-weight: 600;
          margin: 0;
        }

        span {
          margin: 0;
          font-size: 15px;
          color: #222;
        }
      }
    }
  }

  p.info-title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 600!important;
    margin: 38px 0 15px 0;
    color: #000;
  }

  .active-btn{
    background-color: #2A8DCE!important;
    color: #fff!important;
  }
  .create_ticket_wrapper {
    display: flex;
    margin-bottom: 37px;
    flex-wrap: wrap;

    .ticket_item {
      
      width: 130px;
      padding: 12px 18px;
      margin-bottom: 20px;
      border-radius: 6px;
      margin-right: 20px;
      border: 1px solid #DFE4E9;
      position: relative;

      h5 {
        margin: 0 0 5px 0;
        font-weight: 600;
        font-size: 20px;
        color: #2D375C;
        overflow: hidden;
        cursor: pointer;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 24px;
      }

      p {
        font-size: 12px;
        line-height: 14px;
        color: #000000;
      }

      &::after {
        display: block;
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        top: 24px;
        clip-path: circle(50% at 50% 50%);
        left: -10px;
        border-top: 1px solid #DFE4E9;
        transform: rotate(45deg);
        border-radius: 50%;
        background-color: #ffffff;
        border-right: 1px solid #DFE4E9;
      }

      &::before {
        display: block;
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        top: 24px;
        clip-path: circle(50% at 50% 50%);
        right: -10px;
        border-top: 1px solid #DFE4E9;
        transform: rotate(-45deg);
        border-radius: 50%;
        background-color: #ffffff;
        border-left: 1px solid #DFE4E9;
      }
      
    }
  }
}



.upcoming {
  background: #6ABCDE !important;
}

.postpon {
  background-color: #444 !important;
}

.cancel,
.draft {
  background: #7788CB !important;
}

.ongoing {
  background: #3CBF6C !important;
}

.complete {
  background: #F38942 !important;
}


.draft {
  text-align: center;
  // background: #103259;
  background: #222 !important;
  padding: 2px 5px;
  color: white;
  border-radius: 5px;
}

.status-label {
  padding: 4px 8px;
  text-align: center;
  display: inline-block;
  font-size: 13px;
  border-radius: 4px;
  color: #fff;
}


.cancel,
.ps-draft {
  background-color: #7788CB !important;
}
.ps-upcomming{
  background-color: #6ABCDE !important;
}
.ps-ongoing{
  background-color: #3CBF6C!important;
}
.ps-completed{
  background-color: #F38942!important;
}

.poll-timer {
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 6px;
    background-color: #3178B1;
  }
}

.upcoming{
  background: #6ABCDE !important; 
}
.postpon{
  background-color: #444 !important;
}
.draft{
  background: #7788CB !important;
}
.ongoing{
  background: #3CBF6C !important;
}
.complete{
  background: #F38942 !important;
}
.incomplete{
  background: #bebebe !important;
}
.readytopublish{
  width: 135px!important;
  background: #2E7704 !important;
}
.cancel{
  background-color: #2a2626!important;
}